import React from "react";

export default function Home() {
  return (
    <main>
      <div className="fixed w-full h-full sm:bg-game-controller-landscape bg-game-controller-vertical bg-cover	"></div>
      <div className=""></div>
    </main>
  );
}
