import React, { useState, useEffect } from "react";
import sanityClient from "../client.js";
import { Link } from "react-router-dom";

export default function Projects() {
  const [projectData, setProjectData] = useState(null);
  const el = document.querySelector(".loader-container");
  if (document.readyState !== "complete") {
    el.classList.remove("loader--hide");
  }

  window.scrollTo(0, 0);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "project"] | order(date){
          title,
          date,
          description,
          slug,
          mainImage{
            asset->{
                _id,
                url
              },
              alt
          }     
        }`
      )
      .then((data) => setProjectData(data))
      .catch(console.error);
  }, []);

  useEffect(() => {
    document.title = "Portfolio | Projects";
  });

  if (!projectData) {
    el.classList.remove("loader--hide");
    return <div>Loading...</div>;
  }

  return (
    <main
      onLoad={() => {
        document.title = "Portfolio | Projects";
        el.classList.add("loader--hide");
      }}
    >
      <section className="container mx-auto pb-24 md:pb-8 lg:pb-32 lg:pl-5 sm:w-11/12">
        {projectData &&
          projectData.map((project, index) => (
            <article
              className="relative lg:pt-60 lg:block lg:pt-0 pt-14"
              key={project.title}
            >
              <div className="md:hidden block">
                <Link
                  to={"/projects/" + project.slug.current}
                  key={project.slug.current}
                >
                  <img
                    src={project.mainImage.asset.url}
                    alt={project.mainImage.alt}
                    draggable={false}
                    className="w-full h-72 shadow-2xl transform transition duration-500 hover:scale-105"
                  />
                </Link>
                <div className=" pt-2 px-2">
                  <h1 className="text-white text-4xl text-center border-4 p-5 shadow-2xl min-w-max">
                    {project.title}
                  </h1>
                </div>
              </div>
              <div className="md:flex hidden lg:justify-between ">
                {index % 2 === 0 && (
                  <Link
                    to={"/projects/" + project.slug.current}
                    key={project.slug.current}
                  >
                    <img
                      src={project.mainImage.asset.url}
                      alt={project.mainImage.alt}
                      draggable={false}
                      className="lg:w-full lg:h-full md:h-10/12 w-full h-72 shadow-2xl transform transition duration-500 hover:scale-105"
                    />
                  </Link>
                )}
                {index % 2 === 0 && (
                  <div className="md:place-self-center md:pl-2 justify-between">
                    <h1 className="2xl:text-7xl xl:text-6xl lg:text-5xl md:text-4xl sm:text-4xl text-white text-center border-4 p-5 shadow-2xl min-w-max">
                      {project.title}
                    </h1>
                  </div>
                )}
                {index % 2 === 1 && (
                  <div className="md:place-self-center md:pr-10 ">
                    <h1 className="2xl:text-7xl xl:text-6xl lg:text-5xl md:text-4xl sm:text-4xl text-white text-center border-4 p-5 shadow-2xl min-w-max">
                      {project.title}
                    </h1>
                  </div>
                )}
                {index % 2 === 1 && (
                  <Link
                    to={"/projects/" + project.slug.current}
                    key={project.slug.current}
                  >
                    <img
                      src={project.mainImage.asset.url}
                      alt={project.mainImage.alt}
                      draggable={false}
                      className="lg:w-full lg:h-full md:h-10/12 w-full h-72 shadow-2xl transform transition duration-500 hover:scale-105"
                    />
                  </Link>
                )}
              </div>
            </article>
          ))}
      </section>
    </main>
  );
}
