import React from "react";

export default class CarouselLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = { active: true, grabbing: false };
    this.onMouseMove = this.setMouseMove.bind(this);
  }

  setMouseMove(e) {
    e.preventDefault();
    this.setState({ active: false });

    let timeout;
    (() => {
      clearTimeout(timeout);
      timeout = setTimeout(() => this.setState({ active: true }), 50);
    })();
  }

  render() {
    return (
      <div className="md:pb-5 lg:pb-16">
        <div
          onMouseMove={(e) => this.setMouseMove(e)}
          className={` hidden lg:flex ${
            this.state.active ? "" : "link-disable"
          }`}
        >
          {this.props.children}
        </div>
        <div
          onMouseMove={(e) => this.setMouseMove(e)}
          className="flex lg:hidden"
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
