import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./Components/Home";
import About from "./Components/About";
import DevLog from "./Components/DevLog";
import SingleProject from "./Components/SingleProject";
import Projects from "./Components/Projects";
import NavBarTop from "./Components/NavBarTop";
import NavBarBottom from "./Components/NavBarBottom";
import BackGround from "./Components/BackGround";

function App() {
  return (
    <BrowserRouter>
      <BackGround></BackGround>
      <Switch>
        <Route component={Home} path="/" exact />
        <Route component={About} path="/aboutme" />
        <Route component={DevLog} path="/projects/:projectSlug/:devLogSlug" />
        <Route component={SingleProject} path="/projects/:projectSlug" />
        <Route component={Projects} path="/projects" />
      </Switch>
      <NavBarTop></NavBarTop>
      <NavBarBottom></NavBarBottom>
    </BrowserRouter>
  );
}

export default App;
