import React from "react";
import { SocialIcon } from "react-social-icons";
import { BuyMeACoffee } from "./BuyMeACoffeeWidget";

export default function NavBar() {
  return (
    <header>
      <nav className="fixed bottom-0 left-0 w-74">
        <div id="social-icon-div" className="inline-flex py-3 px-3 my-6">
          <SocialIcon
            url="http://bit.ly/twitter-ismaelgamedev"
            network="twitter"
            className="mr-4"
            target="_blank"
            fgColor="#fff"
            style={{ height: 50, width: 50 }}
          ></SocialIcon>
          <SocialIcon
            url="http://bit.ly/instagram-ismaelgamedev"
            network="instagram"
            className="mr-4"
            target="_blank"
            fgColor="#fff"
            style={{ height: 50, width: 50 }}
          ></SocialIcon>
          <SocialIcon
            url="http://bit.ly/itchio-ismaelgamedev"
            network="itch"
            className="mr-4"
            target="_blank"
            fgColor="#fff"
            style={{ height: 50, width: 50 }}
          ></SocialIcon>
          <BuyMeACoffee></BuyMeACoffee>
        </div>
      </nav>
    </header>
  );
}
