import React, { useState, useEffect } from "react";
import sanityClient from "../client.js";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import CarouselLink from "./CarouselLink";

const portfolioRresponsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const CustomDot = ({ onClick, ...rest }) => {
  const { index, active } = rest;
  const carouselItems = ["", "", "", "", ""];
  // onMove means if dragging or swiping in progress.
  // active is provided by this lib for checking if the item is active or not.
  return (
    <li
      data-index={carouselItems[index]}
      className={`custom-react-multi-carousel-dot ${
        active ? "custom-react-multi-carousel-dot--active" : ""
      }`}
    >
      <button
        className={active ? "active" : "inactive"}
        onClick={() => onClick()}
      >
        {React.Children.toArray(carouselItems)[index]}
      </button>
    </li>
  );
};

export default function Home() {
  window.scrollTo(0, 0);

  const el = document.querySelector(".loader-container");
  if (document.readyState !== "complete") {
    el.classList.remove("loader--hide");
  }

  const [projects, setProjects] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "project"]  | order(date) {
          title,
          summary,
          slug,
          mainImage{
            asset->{
                _id,
                url
              },
              alt
          }     
        }`
      )
      .then((data) => setProjects(data))
      .catch(console.error);
  }, []);

  if (!projects) {
    el.classList.remove("loader--hide");
    return <div>Loading...</div>;
  }

  return (
    <main
      onLoad={() => {
        document.title = "Portfolio | Home";
        el.classList.add("loader--hide");
      }}
    >
      <div className="container mx-auto relative lg:w-2/4 md:w-9/12 sm:w-full w-5/6 xl:pt-60 lg:pt-80 md:pt-24 pt-56">
        <section className="flex justify-center lg:p-20 p-5 border-4 shadow-2xl">
          <h2 className="2xl:text-7xl xl:text-6xl lg:text-5xl md:text-4xl sm:text-4xl text-4xl text-white text-center">
            Hey there! I'm a self taught game developer based in London.
            Portfolio - WIP 🚧
          </h2>
        </section>
      </div>
      <div className="container mx-auto relative xl:pt-60 lg:pt-96 md:pt-32 pt-60 2xl:w-11/12 xl:w-11/12 lg:w-11/12 md:w-3/4 sm:w-full w-full">
        <section className="lg:flex lg:justify-start md:hidden flex justify-center lg:pt-10">
          <h1 className="2xl:text-7xl xl:text-6xl lg:text-5xl md:text-4xl sm:text-4xl text-4xl text-white text-center">
            Portfolio
          </h1>
        </section>
      </div>
      <div className="container mx-auto relative md:pb-3 pb-24 sm:w-11/12 md:pt-2 lg:pt-20 lg:pb-10">
        <div className="hidden lg:block md:block cursor-pointer">
          <Carousel
            showDots={true}
            renderDotsOutside={false}
            responsive={portfolioRresponsive}
            dotListClass="custom-react-multi-carousel-dot-list"
            customDot={<CustomDot></CustomDot>}
            arrows={false}
            keyBoardControl={false}
            infinite={true}
            autoPlay={false}
            autoPlaySpeed={3000}
            draggable={true}
            centerMode={false}
            minimumTouchDrag={10}
            items={1}
          >
            {projects &&
              projects.map((project, index) => (
                <CarouselLink key={project.slug.current}>
                  <Link
                    to={"/projects/" + project.slug.current}
                    draggable={false}
                  >
                    {/*940x529 */}
                    <img
                      className="md:w-11/12"
                      draggable={false}
                      src={project.mainImage.asset.url}
                      alt={project.mainImage.alt}
                    ></img>
                  </Link>
                  <div className="lg:hidden md:w-5/12 place-self-center shadow-2xl">
                    <h1 className="md:text-4xl text-center text-white border-4 p-2">
                      {project.title}
                    </h1>
                  </div>
                  <div className="hidden lg:block w-4/12 p-12 border-4 place-self-center shadow-2xl">
                    <h1 className="2xl:text-5xl xl:text-4xl lg:text-3xl md:text-2xl sm:text-xl text-xl text-white text-center">
                      {project.title}
                    </h1>
                    <h2 className="2xl:text-4xl xl:text-3xl lg:text-2xl md:text-xl sm:text-xl text-lg pt-3 text-white text-center ">
                      {project.summary}
                    </h2>
                  </div>
                </CarouselLink>
              ))}
            {!projects && <div className="empty"></div>}
          </Carousel>
        </div>
        <div className="container mx-auto relative md:hidden cursor-pointer">
          {projects &&
            projects.map((project, index) => (
              <Link
                to={"/projects/" + project.slug.current}
                draggable={false}
                key={project.slug.current}
              >
                <div className="px-2 pt-10">
                  <h1 className="text-3xl text-white text-center p-2 border-4 shadow-2xl">
                    {project.title}
                  </h1>
                </div>
                <img
                  className="pt-2 w-full h-72 shadow-2xl"
                  draggable={false}
                  src={project.mainImage.asset.url}
                  alt={project.mainImage.alt}
                ></img>
              </Link>
            ))}
        </div>
      </div>
    </main>
  );
}
