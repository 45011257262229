import React, { useState, useEffect } from "react";
import sanityClient, { sanityDataset } from "../client.js";
import imageUrlBuilder from "@sanity/image-url";
import BlockContent from "@sanity/block-content-to-react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const builder = imageUrlBuilder(sanityClient);

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1536 },
    items: 4,
    slidesToSlide: 1, // optional, default to 1.
  },
  desktopSmall: {
    breakpoint: { max: 1536, min: 1024 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

function urlFor(source) {
  return builder.image(source);
}

export default function About() {
  const [author, setAuthor] = useState(null);
  const [games, setGames] = useState(null);
  const [books, setBooks] = useState(null);

  window.scrollTo(0, 0);

  const el = document.querySelector(".loader-container");

  if (document.readyState !== "complete") {
    el.classList.remove("loader--hide");
  }

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "author"]{
      name,
      bio,
      "authorImage": image.asset->url
    }`
      )
      .then((data) => setAuthor(data[0]))
      .catch(console.error);
  }, []);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "game"]{
      title,
      mainImage{
        asset->{
          _id,
          url
        }
      }
    }`
      )
      .then((data) => setGames(data))
      .catch(console.error);
  }, []);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "book"]{
      title,
      mainImage{
        asset->{
          _id,
          url
        }
      }
    }`
      )
      .then((data) => setBooks(data))
      .catch(console.error);
  }, []);

  if (!author || !games || !books) {
    el.classList.remove("loader--hide");
    return <div>Loading...</div>;
  }

  return (
    <main
      onLoad={() => {
        document.title = "Portfolio | About me";
        el.classList.add("loader--hide");
      }}
    >
      <div
        className="container mx-auto relative pt-14 2xl:pt-60 xl:pt-60 lg:pt-24 2xl:flex xl:flex lg:block md:flex justify-center sm:w-11/12 md:w-full"
        key={author.name}
      >
        {/*940x529 */}
        <img
          draggable={false}
          className="2xl:w-full 2xl:h-full xl:w-8/12 xl:h-3/6 lg:w-full lg:h-full md:w-7/12 w-full h-72 shadow-2xl place-self-center"
          src={urlFor(author.authorImage).url()}
          alt={author.name}
        ></img>
        <div className="px-2 sm:py-0 py-2 place-self-center lg:pt-2 xl:pt-0">
          <h2 className="2xl:text-4xl xl:text-3xl lg:text-2xl md:text-lg text-2xl text-white text-center border-4 p-4 md:p-0 lg:p-4 shadow-2xl">
            <BlockContent
              blocks={author.bio}
              projectId="jwympeia"
              dataset={sanityDataset}
            ></BlockContent>
          </h2>
        </div>
      </div>
      <section className="container mx-auto relative flex justify-center pt-12 md:pt-10 lg:pt-24 xl:pt-44 pb-6">
        <h1 className="2xl:text-7xl xl:text-6xl lg:text-5xl md:text-4xl sm:text-4xl text-4xl text-white text-center w-11/12">
          {" "}
        </h1>
      </section>
      <div className="container mx-auto relative flex justify-center pt-4 md:pt-3 lg:pt-4 pb-2">
        <h1 className="2xl:text-5xl xl:text-4xl lg:text-3xl md:text-2xl sm:text-xl text-2xl text-white text-center w-3/12 border-4 shadow-2xl py-2">
          Games
        </h1>
      </div>
      <div className="md:p-5 container mx-auto relative w-11/12 h-5/6">
        <Carousel
          responsive={responsive}
          arrows={false}
          keyBoardControl={false}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          draggable={false}
          centerMode={false}
        >
          {games &&
            games.map((game, index) => (
              <div key={game.title} className="px-2">
                {/*940x529*/}
                <img
                  className="*"
                  draggable={false}
                  src={game.mainImage.asset.url}
                  alt={game.mainImage.alt}
                ></img>
              </div>
            ))}
          {!games && <div className="empty"></div>}
        </Carousel>
      </div>
      <div className="container mx-auto relative flex justify-center pt-10 md:pt-3 lg:pt-4 pb-2">
        <h1 className="2xl:text-5xl xl:text-4xl lg:text-3xl md:text-2xl sm:text-xl text-2xl text-white text-center w-3/12 border-4 shadow-2xl py-2">
          Books
        </h1>
      </div>
      <div className="md:p-5 container mx-auto relative pb-20 md:pb-10 lg:pb-28 xl:pb-24 2xl:pb-24 w-11/12 h-5/6">
        <Carousel
          responsive={responsive}
          arrows={false}
          keyBoardControl={false}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={3000}
          draggable={false}
          centerMode={false}
        >
          {books &&
            books.map((book, index) => (
              <div key={book.title} className="flex justify-center">
                {/*1410x2250*/}
                <img
                  draggable={false}
                  className="max-h-80"
                  src={book.mainImage.asset.url}
                  alt={book.mainImage.alt}
                ></img>
              </div>
            ))}
          {!books && <div className="empty"></div>}
        </Carousel>
      </div>
    </main>
  );
}
