import React from "react";

export class BuyMeACoffee extends React.Component {
  constructor(props) {
    super(props);
    let script = document.createElement("script");
    script.setAttribute("data-name", "BMC-Widget");
    script.src = "/assets/js/scriptbmc.js";
    script.setAttribute("data-id", "ismael.gamedev");
    script.setAttribute("data-description", "Thank you for your support!");
    script.setAttribute("data-message", "");
    script.setAttribute("data-color", "#FFDD00");
    script.setAttribute("data-position", "left");
    script.setAttribute("data-width", "50");
    script.setAttribute("data-height", "50");
    script.setAttribute("data-x_margin", "35");
    script.setAttribute("data-y_margin", "35");

    script.async = true;
    //Call window on load to show the image
    script.onload = function () {
      var evt = document.createEvent("Event");
      evt.initEvent("DOMContentLoaded", false, false);
      window.dispatchEvent(evt);
    };
    this.script = script;
  }

  componentDidMount() {
    document.head.appendChild(this.script);
  }

  componentWillUnmount() {
    document.head.removeChild(this.script);
  }

  render() {
    return null;
  }
}
