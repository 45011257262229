import sanityClient from "@sanity/client";

export const sanityDataset = "test";

export default sanityClient({
  projectId: "jwympeia",
  dataset: sanityDataset,
  apiVersion: "2021-03-25",
  useCdn: true,
});
