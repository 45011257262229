import React, { useState, useEffect } from "react";
import sanityClient, { sanityDataset } from "../client.js";
import { Link } from "react-router-dom";
import LeaderLine from "react-leader-line";
import BlockContent from "@sanity/block-content-to-react";

let columns = 0;

let projectGoalsStructure = [];
let GoalsButton = [];
let AllLines = [];
let GoalsPanel = [];

let totalGoals = 0;

let Structure = {
  GoalRow: function GoalRow(props) {
    return (
      <div className="container">
        <div
          className={`grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-24 ${
            props.Row % 2 === 0 ? "goalsInverse" : ""
          }`}
        >
          {props.Buttons}
        </div>
        {props.Panels}
      </div>
    );
  },
};

let Panels = {
  PanelGoal: function PanelGoal(props) {
    return (
      <div className="pt-10 2xl:px-10 xl:px-10 pb-10 container mx-auto relative flex justify-center">
        {props.Panels}
      </div>
    );
  },
};

export default function ProjectGoals(props) {
  const [projectGoalsData, setProjectGoalsData] = useState(null);
  const [status, setStatus] = useState("close");

  let projectGoalStatus = status;
  let projectTitle = props.project;

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "projectGoal" && project->title=="${projectTitle}"] | order(dateStart){
          title,
          _id,
          slug,
          description,
          status,
          "projectSlug":project->slug,
          devLogs[]->{
            _id,
            title,
            slug,
            subTitle,
            publishedAt,
            mainImage{
                asset->{
                    _id,
                    url
                },
                alt
            }        
          }     
        }`
      )
      .then((data) => setProjectGoalsData(data))
      .catch(console.error);

    window.addEventListener("resize", setNumberOfColumns);

    return () => {
      window.removeEventListener("resize", setNumberOfColumns);
      removeAllLines();
    };
  }, [projectTitle]);

  if (!projectGoalsData && projectGoalStatus === "close")
    return <div>Loading...</div>;

  totalGoals = projectGoalsData.length;

  function setNumberOfColumns() {
    let newColumnValue = 1;
    if (window.innerWidth >= 1280) {
      newColumnValue = 4;
    } else if (window.innerWidth >= 1024) {
      newColumnValue = 3;
    } else if (window.innerWidth >= 768) {
      newColumnValue = 3;
    } else if (window.innerWidth >= 640) {
      newColumnValue = 2;
    }

    if (columns !== newColumnValue) {
      columns = newColumnValue;
      setStatus("resize" + columns);
    }
  }

  function removeAllLines() {
    AllLines.forEach((line) => {
      line.remove();
    });
    AllLines.length = 0;
  }

  function CreateLines(totalGoals) {
    if (AllLines) {
      removeAllLines();
    }

    for (let i = 1; i < totalGoals; i++) {
      if (i > 0) {
        let start = document.getElementById("GoalsButton" + (i - 1));
        let end = document.getElementById("GoalsButton" + i);

        var line = new LeaderLine(start, end, {
          color: "white",
          dropShadow: { opacity: 0.3 },
        });
        AllLines.push(line);
      }
    }
  }

  function UpdateAllLines() {
    AllLines.forEach((line) => {
      line.position();
    });
  }

  function CloseGoalPanel(id) {
    let goalsPanel = document.getElementsByClassName("open");
    for (let index = 0; index < goalsPanel.length; index++) {
      if (goalsPanel[index].id !== id) {
        goalsPanel[index].className = "hidden close over";
        UpdateAllLines();
      }
    }
  }

  function scrollToTarget(element) {
    window.scrollTo({
      top: findPosition(element),
      behavior: "smooth",
    });
  }

  function findPosition(obj) {
    var currenttop = 0;
    if (obj.offsetParent) {
      do {
        currenttop += obj.offsetTop - 20;
      } while ((obj = obj.offsetParent));
      return [currenttop];
    }
  }

  function OpenGoalPanel(id) {
    var x = document.getElementById(id);
    if (x.className.includes("close")) {
      x.className = "open bg-blue-900 bg-opacity-25 border-4 shadow-2xl over";
      scrollToTarget(x);
    } else {
      x.className = "hidden close over";
    }
    UpdateAllLines();
  }

  function HandleClick(id) {
    CloseGoalPanel(id);
    OpenGoalPanel(id);
  }

  function getGoalStatus(status) {
    let color = "";
    switch (status[0]) {
      case "Completed":
        color = "bg-green-500";
        break;

      case "InProgress":
        color = "bg-yellow-500";
        break;
      case "Pending":
        color = "bg-gray-500";
        break;
      default:
        color = "bg-gray-500";
        break;
    }
    return color;
  }

  function getDisabled(color) {
    if (color === "bg-gray-500") {
      return true;
    }
    return false;
  }

  function CreateStructure(totalGoals, totalColumns) {
    projectGoalsStructure.splice(0, projectGoalsStructure.length);
    GoalsButton.splice(0, GoalsButton.length);
    GoalsPanel.splice(0, GoalsPanel.length);

    let rowCounter = 0;
    for (let i = 0; i < totalGoals; i += totalColumns) {
      rowCounter++;
      GoalsButton = [];
      GoalsPanel = [];
      for (let j = i; j < i + totalColumns && j < totalGoals; j++) {
        let statusColor = getGoalStatus(projectGoalsData[j].status);
        let buttonDisabled = getDisabled(statusColor);
        GoalsButton.push(
          <button
            key={"GoalsButton" + j}
            id={`GoalsButton${j}`}
            disabled={buttonDisabled}
            className={`relative flex justify-center items-center  ${statusColor} text-white text-xl font-bold border-4 focus:outline-none shadow-2xl rounded-full h-28 ${
              j === 0 || j === projectGoalsData.length - 1
                ? "w-28 place-self-center"
                : ""
            } ${
              statusColor === "bg-gray-500"
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-" + statusColor + "-700"
            }`}
            onClick={() => {
              HandleClick("GoalPanel" + j);
            }}
          >
            <h2 className="md:p-2"> {projectGoalsData[j].title}</h2>
          </button>
        );
        GoalsPanel.push(
          <div
            key={"GoalsPanel" + j}
            id={`GoalPanel${j}`}
            className="hidden close over"
          >
            <div className="relative flex  inline-grid xl:grid-cols-2 xl:gap-x-4 grid-cols-1 gap-y-4 text-white place-self-center text-center justify-between">
              <div className="2xl:pt-8 xl:pt-6 lg:pt-10 sm:pt-8 pt-4 2xl:pb-8 xl:pb-6 min-w-min place-self-center justify-center w-11/12">
                <div className="p-2 border-4 shadow-2xl justify-center">
                  <h1 className="lg:text-6xl text-4xl">
                    {projectGoalsData[j].title}
                  </h1>
                  <h2 className="md:prose-lg prose-sm text-white">
                    {projectGoalsData[j].description && (
                      <BlockContent
                        blocks={projectGoalsData[j].description}
                        projectId="jwympeia"
                        dataset={sanityDataset}
                      ></BlockContent>
                    )}
                  </h2>
                </div>
              </div>
              <div className="place-self-center justify-center pt-6">
                <div className="container mx-auto relative flex justify-center">
                  <h1 className="lg:text-4xl md:text-3xl text-3xl border-4 shadow-2xl p-2">
                    Dev Logs
                  </h1>
                </div>
                <div
                  className={`pt-6 xl:block md:grid ${
                    projectGoalsData[j].devLogs !== null &&
                    projectGoalsData[j].devLogs.length > 0
                      ? "md:grid-cols-2"
                      : "md:grid-cols-1"
                  } md:gap-4 md:place-items-center`}
                >
                  {projectGoalsData &&
                    projectGoalsData[j].devLogs &&
                    projectGoalsData[j].devLogs.map((devlogs) => (
                      <div
                        className="container mx-auto relative flex justify-center pb-10 h-5/6 w-11/12 transform transition duration-500 hover:scale-105"
                        key={devlogs.title}
                      >
                        <Link
                          to={
                            projectGoalsData[j].projectSlug.current +
                            "/" +
                            devlogs.slug.current
                          }
                          draggable={false}
                        >
                          <div className="absolute md:h-full h-5/6 w-full flex items-center justify-center">
                            <div className="bg-blue-900 bg-opacity-25 rounded p-2 text-white text-center">
                              <h1 className="lg:text-4xl text-3xl mb-2">
                                {devlogs.title}
                              </h1>
                              <h2 className="lg:text-3xl text-2xl mb-2">
                                {devlogs.subTitle}
                              </h2>
                              <h2 className="lg:text-2xl text-xl mb-2">
                                {new Date(
                                  devlogs.publishedAt
                                ).toLocaleDateString()}
                              </h2>
                            </div>
                          </div>
                          <div className="md:flex md:place-self-center md:justify-center">
                            {/*940x529 */}
                            <img
                              draggable={false}
                              src={devlogs.mainImage.asset.url}
                              alt={devlogs.mainImage.alt}
                            ></img>
                          </div>
                        </Link>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        );
      }
      projectGoalsStructure.push(
        <Structure.GoalRow
          key={"ProjectGoals" + i}
          Row={rowCounter}
          Buttons={GoalsButton}
          Panels={<Panels.PanelGoal Panels={GoalsPanel} />}
        />
      );
    }
  }

  setNumberOfColumns();

  CreateStructure(totalGoals, columns);

  return (
    <div>
      <section
        onLoad={() => {
          CreateLines(totalGoals);
        }}
        className="container mx-auto 2xl:pt-24 xl:pt-16 lg:pt-24 md:pt-0 pt-10 2xl:pb-10 xl:pb-10 lg:pb-10 md:pb-1 pb-24 sm:w-11/12 sm:px-0 px-2"
      >
        {projectGoalsStructure}
      </section>
    </div>
  );
}
