import React from "react";
import { NavLink } from "react-router-dom";

export default function NavBar() {
  return (
    <header>
      <nav className="fixed top-0 inset-x-0">
        <div className="flex justify-between items-end py-3 px-5">
          <NavLink
            to="/projects"
            className="lg:text-3xl md:text-xl sm:text-xl text-xl font-bold text-white  hover:text-black"
          >
            <h1>Projects</h1>
          </NavLink>
          <NavLink
            to="/"
            exact
            className="lg:text-5xl md:text-3xl sm:text-2xl text-2xl font-bold tracking-widest text-white hover:text-black"
          >
            <h1>Ismael</h1>
          </NavLink>
          <NavLink
            to="/aboutme"
            className="lg:text-3xl md:text-xl sm:text-xl text-xl font-bold text-white  hover:text-black"
          >
            <h1>About Me</h1>
          </NavLink>
        </div>
      </nav>
    </header>
  );
}
