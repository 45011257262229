import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "../client.js";
import ProjectGoals from "./ProjectGoals";

export default function SingleProject() {
  const [devLogData, setDevLogData] = useState(null);
  const { projectSlug } = useParams();

  window.scrollTo(0, 0);
  const el = document.querySelector(".loader-container");
  if (document.readyState !== "complete") {
    el.classList.remove("loader--hide");
  }
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type=="project" && slug.current == "${projectSlug}"]{
          slug,
          title,
          description,
          mainImage{
            asset->{
                _id,
                url
              },
              alt
          },     
          devLogs[]->{
            _id,
            title,
            slug,
            subTitle,
            publishedAt,
            mainImage{
                asset->{
                    _id,
                    url
                },
                alt
            }        
          }     
        }`
      )
      .then((data) => setDevLogData(data))
      .catch(console.error);
  }, [projectSlug]);

  if (!devLogData) {
    el.classList.remove("loader--hide");
    return <div>Loading...</div>;
  }

  return (
    <main
      onLoad={() => {
        el.classList.add("loader--hide");
      }}
    >
      {devLogData &&
        devLogData.map((project) => (
          <div className="relative block" key={project.title}>
            <div
              className="container mx-auto relative pt-14 2xl:pt-48 xl:pt-36 lg:pt-28 2xl:flex xl:flex lg:block md:flex justify-center sm:w-11/12"
              onLoad={() => {
                document.title = "Portfolio | " + project.title;
              }}
            >
              {/*940x529 */}
              <img
                draggable={false}
                className="2xl:w-full 2xl:h-full xl:w-8/12 xl:h-3/6 lg:w-full lg:h-full md:w-7/12 md:h-10/12 w-full h-72 place-self-center"
                src={project.mainImage.asset.url}
                alt={project.mainImage.alt}
              ></img>
              <div className="px-2 place-self-center xl:pt-0 pt-2 ">
                <div className="px-1 md:p-2 border-4 shadow-2xl ">
                  <h1 className="2xl:text-6xl xl:text-5xl lg:text-4xl md:text-4xl sm:text-4xl text-4xl text-white text-center sm:pt-0 pt-2 block md:hidden lg:block">
                    {project.title}
                  </h1>
                  <h2 className="2xl:text-3xl xl:text-2xl lg:text-2xl md:text-base text-2xl text-white text-center pt-2">
                    {project.description}
                  </h2>
                </div>
              </div>
            </div>
            <div className="container mx-auto flex relative justify-center h-5/6 w-11/12 pt-12">
              <div className="shadow-2xl bg-red-400 hover:bg-red-400 rounded transform transition duration-500 hover:scale-105">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={"http://bit.ly/itchio-" + project.slug.current}
                >
                  <h2 className="text-2xl text-white font-bold p-2 md:p-2 lg:p-4 xl:p-6 2xl:p-8">
                    Download on itchio!
                  </h2>
                </a>
              </div>
            </div>
          </div>
        ))}
      <div className="container mx-auto relative flex justify-center md:pb-10 pt-10 md:pt-10 lg:pt-20 xl:pt-40 2xl:pt-32">
        <h1 className="2xl:text-5xl xl:text-4xl lg:text-3xl md:text-2xl sm:text-xl text-4xl text-white text-center sm:w-3/12 min-w-max border-4 shadow-2xl p-2">
          RoadMap
        </h1>
      </div>
      {devLogData &&
        devLogData.map((project) => (
          <ProjectGoals
            key={"ProjectGoals"}
            project={project.title}
          ></ProjectGoals>
        ))}
    </main>
  );
}
