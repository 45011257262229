import React, { useState, useEffect } from "react";
import sanityClient, { sanityDataset } from "../client.js";
import { Link, useParams } from "react-router-dom";
import BlockContent from "@sanity/block-content-to-react";

export function isUniqueAcrossAllDocuments() {
  return true;
}

export default function DevLog() {
  const [devLog, setDevLog] = useState(null);
  const [project, setProject] = useState(null);
  const { projectSlug, devLogSlug } = useParams();

  window.scrollTo(0, 0);

  const el = document.querySelector(".loader-container");
  if (document.readyState !== "complete") {
    el.classList.remove("loader--hide");
  }

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "project"] | order(date){
          title,
          date,
          description,
          slug,
          mainImage{
            asset->{
                _id,
                url
              },
              alt
          }     
        }`
      )
      .then((data) => setProject(data))
      .catch(console.error);
  }, []);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "devlog" && project->slug.current=="${projectSlug}"] | order(publishedAt){
          title,
          _id,
          slug,
          "projectSlug":project->slug,
          mainImage{
            asset->{
              _id,
              url
            }
          },
          body,
          subTitle
        }`
      )
      .then((data) => setDevLog(data))
      .catch(console.error);
  }, [projectSlug]);

  if (!devLog || !project) {
    el.classList.remove("loader--hide");
    return <div>Loading...</div>;
  }

  let currentDevLog;
  let previousDevLog;
  let nextDevLog;

  let previousProject = false;
  let nextProject = false;
  let currentProject;

  for (let index = 0; index < devLog.length; index++) {
    if (devLog[index].slug.current === devLogSlug) {
      currentDevLog = devLog[index];
      if (index !== 0) {
        previousDevLog = devLog[index - 1];
      }
      if (index + 1 < devLog.length) {
        nextDevLog = devLog[index + 1];
      }
    }
  }

  for (let index = 0; index < project.length; index++) {
    if (project[index].slug.current === projectSlug) {
      currentProject = project[index];
    }
  }

  if (!previousDevLog) {
    for (let index = 0; index < project.length; index++) {
      if (project[index].slug.current === projectSlug) {
        if (index !== 0) {
          previousProject = true;
          previousDevLog = project[index - 1];
        }
      }
    }
  }

  if (!nextDevLog) {
    for (let index = 0; index < project.length; index++) {
      if (project[index].slug.current === projectSlug) {
        if (index + 1 < project.length) {
          nextProject = true;
          nextDevLog = project[index + 1];
        }
      }
    }
  }

  return (
    <main
      onLoad={() => {
        document.title =
          "Portfolio | " + currentProject.title + " | " + currentDevLog.title;
        el.classList.add("loader--hide");
      }}
    >
      <div className="container mx-auto relative pt-14 2xl:pt-60 xl:pt-60 lg:pt-32 lg:px-0 md:px-2 px-0 2xl:flex xl:flex lg:block md:flex lg:justify-center md:justify-between sm:w-11/12">
        {/*940x529 */}
        <img
          draggable={false}
          className="lg:w-full lg:h-full md:w-8/12 h-72 shadow-2xl place-self-center"
          src={currentDevLog.mainImage.asset.url}
          alt={currentDevLog.mainImage.alt}
        ></img>
        <div className="lg:container px-2 place-self-center xl:pt-0 pt-2 hidden xl:block lg:hidden md:block min-w-max">
          <div className="px-2 md:p-2 border-4 shadow-2xl ">
            <h1 className="2xl:text-6xl xl:text-5xl lg:text-4xl md:text-4xl sm:text-4xl text-4xl text-white text-center sm:pt-0 pt-2 block">
              {currentDevLog.title}
            </h1>
            <h2 className="2xl:text-4xl xl:text-3xl lg:text-2xl md:text-xl text-2xl text-white text-center pt-2">
              {currentDevLog.subTitle}
            </h2>
          </div>
        </div>
      </div>

      <article className="container relative mx-auto sm:w-11/12 pt-2 md:pt-8 px-2 xl:pt-44">
        <div className=" border-4 shadow-2xl lg:p-12 p-6 text-white">
          <div className="xl:hidden lg:block md:hidden block place-self-center border-4 p-2">
            <div className="block">
              <h1 className="text-4xl text-white text-center pt-2">
                {currentDevLog.title}
              </h1>
              <h2 className="text-2xl text-white text-center pt-2">
                {currentDevLog.subTitle}
              </h2>
            </div>
          </div>
          <div className="prose-xl">
            <BlockContent
              blocks={currentDevLog.body}
              projectId="jwympeia"
              dataset={sanityDataset}
            ></BlockContent>
          </div>
        </div>
      </article>

      <div className="pt-10 pb-24">
        <div
          className={`flex space-x-10 ${
            !previousDevLog && nextDevLog ? "justify-end" : "justify-between"
          } items-end`}
        >
          {previousDevLog && (
            <article className="relative w-5/12 h-3/6">
              <Link
                to={`${
                  previousProject
                    ? "/projects/" + previousDevLog.slug.current
                    : previousDevLog.slug.current
                }`}
              >
                <img
                  src={previousDevLog.mainImage.asset.url}
                  alt={previousDevLog.mainImage.alt}
                  className="rounded"
                />
                <span className="absolute bottom-0 right-0">
                  <h2 className="text-white lg:text-4xl text-xl lg:py-4 bg-blue-900 bg-opacity-25 rounded text-center p-1">
                    {previousDevLog.title}
                  </h2>
                </span>
              </Link>
            </article>
          )}
          {nextDevLog && (
            <article className="relative w-5/12 h-3/6">
              <Link
                to={`${
                  nextProject
                    ? "/projects/" + nextDevLog.slug.current
                    : nextDevLog.slug.current
                }`}
              >
                <img
                  src={nextDevLog.mainImage.asset.url}
                  alt={nextDevLog.mainImage.alt}
                  className="rounded"
                />
                <span className="absolute top-0 left-0">
                  <h2 className="text-white lg:text-4xl text-xl lg:py-4 bg-blue-900 bg-opacity-25 rounded text-center p-1">
                    {nextDevLog.title}
                  </h2>
                </span>
              </Link>
            </article>
          )}
        </div>
      </div>
    </main>
  );
}
